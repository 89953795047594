.view-all {
  height: 100vh;
}

.img-h {
  height: 100px;
}

.bg-magenta {
  background-color: #A21C8E;
}

.table thead th {
  vertical-align: middle;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

tbody tr {
  cursor: pointer;
}

/* .btn-add {
  disa;
} */

textarea {
  resize: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
